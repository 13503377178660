<template>
	<div class="layout">
		<div class="top">
			<headSearch></headSearch>
		</div>
		<div class="main">
			<div class="zw"></div>
			<div class="inner">
				<classify></classify>
				<div class="roll">
					<keep-alive>
						<router-view v-if="$route.meta.keepAlive"></router-view>
					</keep-alive>
					<router-view v-if="!$route.meta.keepAlive"></router-view>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import headSearch from "@/components/topBot/headSearch.vue"
	import classify from "@/components/classify.vue"
	export default {
		name: "layout",
		components: {
			headSearch,
			classify
		},
		data() {
			return {

			}
		},
		methods: {

		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo
			}
		},
		created() {
			
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.layout {
		background: #fff;
		position: relative;
	}

	.top {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		/* z-index: 9; */
	}

	.main {
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.zw {
		height: 156px;
	}

	.inner {
		flex: 2;
		overflow: hidden;
		position: relative;
		/* z-index: 20; */
		border-radius: 20px 20px 0 0;
		width: 1300px;
		background: #FFFFFF;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
	}

	.roll {
		flex: 2;
		overflow: hidden;
		position: relative;
	}
</style>
