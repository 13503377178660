<template>
	<div class="headSearch">
		<div class="inner">
			<div class="left">
				<img src="@/assets/log.png" alt="">
			</div>
			<div class="right">
				<div class="top">
					<div class="ss">
						<img src="@/assets/search.png" alt="" class="icon">
						<input type="text" v-model="searchData" @keyup="keyEvent" :placeholder="i18n('69')"
							@focus="searchFocus" @blur="searchBlur" />
						<div class="but" @click="search">{{i18n('70')}}</div>
						<div class="ls" v-show="ls">
							<div class="lsTit">
								<div class="">
									{{i18n('71')}}
								</div>
								<img src="@/assets/dele.png" alt="" @click="deleLs">
							</div>
							<div class="list">
								<div class="item" v-for="(item,index) in searchHistoryData" :key="index"
									@click="wordSearch(item)">
									{{item}}
								</div>
							</div>
						</div>
					</div>
					<div class="cart" @click="$router.push('/cart')">
						<img src="@/assets/cart.png" alt="">{{i18n('72')}}
						<div class="posi" v-if="commodityLenght>0">{{commodityLenght}}</div>
					</div>
				</div>
				<div class="news">
					<el-carousel height="30px" direction="vertical" :autoplay="true" :interval="3000"
						indicator-position="none">
						<el-carousel-item v-for="(item,index) in newList" :key="index">
							<div class="item over">
								<div>{{i18n('73')}}</div>{{item.title}}
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		setCookies,
		getCookies,
		removeCookies
	} from "@/utils/cookies.js"
	import {
		newsList
	} from "@/api/content.js"
	export default {
		name: "headSearch",
		components: {

		},
		data() {
			return {
				newList: [],
				ls: false,
				searchData: "",
				searchHistoryData: JSON.parse(getCookies('searchHistoryData')) || false
			}
		},
		watch: {
			ls() {
				this.searchHistoryData = JSON.parse(getCookies('searchHistoryData')) || false
			}
		},
		computed: {
			headShow() {
				return this.$store.state.user.headShow
			},
			userInfo() {
				return this.$store.state.user.userInfo
			},
			lang() {
				return this.$store.state.i18n.lang
			},
			seleLoca() {
				return this.$store.state.serviceArea.seleLoca
			},
			commodityLenght() {
				return this.$store.state.cart.commodity.length
			},
		},
		methods: {
			windowOpen(url) {
				window.open(url, '_blank')
			},
			keyEvent(event) {
				if (!event.shiftKey && event.keyCode == 13) { //按下 enter 键时，没有按下 shift 键
					//取消默认操作
					event.cancelBubble = true;
					event.preventDefault();
					event.stopPropagation();
					//发送提交事件
					this.search();
				}
			},
			search() {
				let ssData = JSON.parse(getCookies('searchHistoryData')) || []
				let cf = false
				let cfIndex = false
				ssData.map((item, index) => {
					if (item == this.searchData) {
						cf = true
						cfIndex = index
					}
				})
				if (cf) {
					ssData.splice(cfIndex, 1)
				} else if (ssData.length == 10) {
					ssData.splice(9, 1)
				}
				ssData.unshift(this.searchData)
				this.searchHistoryData = ssData
				setCookies('searchHistoryData', JSON.stringify(ssData))

				this.$store.commit("user/setSearchData", this.searchData)
				this.$store.commit("user/setCategoryType", 10)
				if (this.searchData != '' && this.$route.path != '/category') {
					this.$router.push('/category')
				}
			},
			deleLs() {
				removeCookies('searchHistoryData')
				this.ls = false
			},
			searchFocus() {
				if (this.searchHistoryData) {
					this.ls = true
				}
			},
			searchBlur() {
				setTimeout(() => {
					this.ls = false
				}, 1000)
			},
			wordSearch(data) {
				//关键词搜索点击
				this.searchData = data
				console.log(data)
				this.search()
			},
			async getNewsList() {
				console.log(10000000222)
				let lang = this.$store.state.i18n.lang
				if (lang == 'zh') {
					lang = 0
				} else if (lang == 'en') {
					lang = 1
				} else if (lang == 'vie') {
					lang = 2
				}
				let {
					data
				} = await newsList({
					language: lang
				})
				this.newList = data
			}

		},
		mounted() {
			this.getNewsList()
		},
	};
</script>

<style scoped>
	.headSearch {
		padding-top: 32px;
		/* width: 1920px; */
		width: 100%;
		/* width: 1440px; */
		height: 320px;
		background-image: linear-gradient(180deg, #56AB7B 0%, #42BB5B 100%);
		box-sizing: border-box;
	}

	.inner {
		width: 1300px;
		margin: 0 auto;
		padding: 34px 0 0 50px;
		display: flex;
		box-sizing: border-box;
		position: relative;
		z-index: 30;
	}

	.left {
		margin-right: 73px;
	}

	.left img {
		/* width: 147px; */
		height: 58px;
		display: block;
	}

	.right .top {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
	}

	.right .top .ss {
		margin-right: 20px;
		width: 600px;
		height: 36px;
		background: #FFFFFF;
		border-radius: 18px;
		padding: 2px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		position: relative;
	}

	.right .top .ss .ls {
		position: absolute;
		left: 0;
		top: 41px;
		width: 600px;
		background: #FFFFFF;
		border-radius: 8px;
		padding: 0 16px;
		padding-bottom: 10px;
		z-index: 30;
	}

	.right .top .ss .ls .lsTit {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 44px;
	}

	.right .top .ss .ls .lsTit div {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #86909C;
		text-align: right;
		line-height: 22px;
		height: 22px;
		font-weight: 400;
	}

	.right .top .ss .ls .lsTit img {
		width: 16px;
		height: 16px;
		display: block;
	}

	.right .top .ss .ls .list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.right .top .ss .ls .list .item {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 500;
		padding: 0 10px;
		line-height: 28px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 6px;
		margin-right: 8px;
		cursor: pointer;
	}

	.right .top .ss img.icon {
		margin-left: 10px;
		margin-right: 8px;
		width: 13px;
		height: 13px;
	}

	.right .top .ss input {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		font-weight: 400;
		flex: 1;
		line-height: 32px;
	}

	.right .top .ss div.but {
		padding: 0 21px;
		height: 32px;
		background: #56AB7B;
		border-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #FFFFFF;
		font-weight: 400;
		cursor: pointer;
	}

	.right .top .cart {
		display: flex;
		align-items: center;
		height: 36px;
		border: 1px solid rgba(255, 255, 255, 1);
		border-radius: 18px;
		padding: 0 20px;
		position: relative;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #FFFFFF;
		font-weight: 400;
		cursor: pointer;
	}

	.right .top .cart img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.right .top .cart .posi {
		padding: 0 7px;
		height: 20px;
		line-height: 20px;
		background: #F1554D;
		border-radius: 10px;
		position: absolute;
		right: 0px;
		top: -10px;
	}

	.right .news .item {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 15px;
		color: #FFFFFF;
		font-weight: 400;
		width: 600px;
	}

	.right .news .item div {
		padding: 0 9px;
		height: 22px;
		line-height: 22px;
		background: #F39E4E;
		border-radius: 4px;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #FFFFFF;
		font-weight: 400;
		margin-right: 5px;
	}
</style>
