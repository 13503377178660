<template>
	<div class="classify">
		<div class="one">
			<div class="left" @click="classifyShowChange">
				<img src="@/assets/view.png" alt="">
				{{i18n('82')}}
				<img src="@/assets/view-list.png" alt="" v-if="classifyShow">
				<img src="@/assets/view-list-up.png" alt="" v-else>
			</div>
			<div class="right">
				<div :class="categoryType==1?'item itemAc':'item'" @click="goView(2,1)">
					{{i18n('83')}}
				</div>
				<div :class="categoryType==2?'item itemAc':'item'" @click="goView(2,2)">
					{{i18n('84')}}
				</div>
				<div :class="categoryType==3?'item itemAc':'item'" @click="goView(2,3)">
					{{i18n('85')}}
				</div>
				<div class="item" @click="userCenterUrl(3,'/recharge')">
					{{i18n('86')}}
				</div>
				<!-- <div class="item lw" @click="userCenterUrl(1,'/invitation')">
					<img src="@/assets/lw.png" alt=""> {{i18n('87')}}
				</div> -->
			</div>
		</div>
		<div :class="classifyShow?'swiperWap':'swiperWap swiperWapHiden'">
			<div class="swiper-container swiper-container-category swiper">
				<!-- 这边的两个方法是鼠标移入和移出 -->
				<div class="swiper-wrapper">
					<div class="swiper-slide category" v-for="(item,index) in categoryList" :key="index">
						<div class="nr">
							<img :src="item.base.icon" alt="">
							<div class="bot" v-if="lang=='zh'">
								{{item.base.zh_name}}
							</div>
							<div class="bot" v-if="lang=='en'">
								{{item.base.en_name}}
							</div>
							<div class="bot" v-if="lang=='vie'">
								{{item.base.vi_name}}
							</div>
							<div class="posi">
								<div class="child" v-for="(items,indexs) in item.children" :key='indexs' @click="seleCategoryChange(items.id)">
									<span v-if="lang=='zh'">{{items.zh_name}}</span>
									<span v-if="lang=='en'">{{items.en_name}}</span>
									<span v-if="lang=='vie'">{{items.vi_name}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 如果需要导航按钮 -->
				<div class="swiper-button-prev swiper-button-prev-category">
					<img src="@/assets/leftLv.png" alt="">
				</div>
				<!--左箭头。如果放置在swiper外面，需要自定义样式。-->
				<div class="swiper-button-next swiper-button-next-category">
					<img src="@/assets/rightLv.png" alt="">
				</div>
				<!--右箭头。如果放置在swiper外面，需要自定义样式。-->
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper, { Pagination, Navigation, Autoplay } from 'swiper' 
	Swiper.use([Pagination, Navigation, Autoplay])
	import "swiper/swiper-bundle.min.css"; //导入核心 Swiper 样式
	
	import {
		categoryAll
	} from "@/api/commodity.js"
	export default {
		name: "classify",
		components: {

		},
		data() {
			return {
				categoryList:[]
			}
		},
		methods: {
			userCenterUrl(type,url){
				this.$store.commit("user/setUserCenterType", type)
				this.$router.push(url)
			},
			//获取分类
			async categoryAll() {
				let {
					data
				} = await categoryAll()
				this.$store.commit('user/setCategoryList', data)
				this.categoryList = data
				return true
			},
			classifyShowChange(data){
				let classifyShow = !this.classifyShow
				if(data==1 || data==2){
					classifyShow = data == 1?true:false
				}
				this.$store.commit('user/setClassifyShow',classifyShow)
			},
			goView(classifyShow,categoryType){
				this.classifyShowChange(classifyShow)
				this.$store.commit("user/setCategoryType",categoryType)
				if(this.$route.path!='/category'){
					this.$router.push('/category')
				}
			},
			seleCategoryChange(id){
				this.$store.commit("user/setSeleCategory",{childrenId:id})
				this.classifyShowChange(2)
				this.$store.commit("user/setCategoryType",0)
				if(this.$route.path!='/category'){
					this.$router.push('/category')
				}
				
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			seleLoca() {
				return this.$store.state.serviceArea.seleLoca
			},
			classifyShow() {
				return this.$store.state.user.classifyShow
			},
			categoryType() {
				return this.$store.state.user.categoryType
			},
		},
		watch: {
			seleLoca: {
				handler: function() {
					//需要更新数据
					this.categoryAll()
				},
				deep: true
			}
		},
		async mounted() {
			await this.categoryAll()
			new Swiper('.swiper-container-category', {
				slidesPerView: 10,
				slidesPerGroup: 1,
				navigation: {
					nextEl: '.swiper-button-next-category',
					prevEl: '.swiper-button-prev-category',
				},
			})
		}
	};
</script>

<style scoped>
	.swiperWap{
		height: 120px;
		overflow: hidden;
		transition: all .3s;
		position: relative;
		z-index: 20;
	}
	.swiperWapHiden{
		height: 0px;
	}
	.swiperWap:hover{
		overflow: inherit;
	}
	.swiperWapHiden:hover{
		overflow: hidden;
	}
	.swiperWap::after{
		content: '';
		width: 40px;
		height: 100px;
		background: #fff;
		position: absolute;
		left: 0;
		top: 20px;
		z-index: 10;
	}
	.swiperWap::before{
		content: '';
		width: 40px;
		height: 100px;
		background: #fff;
		position: absolute;
		right: 0;
		top: 20px;
		z-index: 10;
	}
	.one {
		width: 100%;
		height: 72px;
		background: #FFFFFF;
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06);
		border-radius: 20px 20px 0px 0px;
		display: flex;
		align-items: center;
	}

	.one .left {
		margin-left: 50px;
		height: 36px;
		cursor: pointer;
		display: flex;
		align-items: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		font-weight: 500;
		margin-right: 143px;
	}

	.one .left img:nth-child(1) {
		width: 16px;
		height: 16px;
		margin: 0 5px 0 7px;
		display: block;
	}

	.one .left img:nth-child(2) {
		width: 16px;
		height: 16px;
		margin: 0 3px 0 5px;
		display: block;
	}

	.one .right {
		display: flex;
		align-items: center;
	}

	.one .right .item {
		height: 36px;
		background: rgba(86, 171, 123, 0.10);
		border-radius: 18px;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		font-weight: 500;
		line-height: 36px;
		padding: 0 25px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
	}
	.one .right .itemAc{
		background: #56AB7B;
		color: #fff;
	}
	.one .right .lw {
		background: rgba(255, 0, 0, 0.10);
	}

	.one .right .lw img {
		width: 20px;
		height: 18px;
		display: block;
		margin-right: 11px;
	}

	.swiper-container {
		width: 1200px;
		height: 120px;
		position: relative;
	}
	.swiper-button-prev-category::after,.swiper-button-next-category::after{
		content: none;
	}
	.swiper-button-next-category img,.swiper-button-prev-category img{
		width: 32px;
		height: 32px;
	}
	.swiper-button-next-category,.swiper-button-prev-category{
		width: 32px;
		height: 32px;
		margin-top: -16px;
	}
	.swiper-button-next-category{
		right: -10px;
	}
	.swiper-button-prev-category{
		left: -10px;
	}
	.category{
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.category .nr{
		cursor: pointer;
		position: relative;
	}
	.category .nr img{
		width: 53px;
		height: 53px;
		display: block;
		margin: 0 auto;
		margin-bottom: 6px;
	}
	.category .nr .bot{
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #232323;
		text-align: center;
		font-weight: 500;
		/* height: 45px; */
	}
	.category .nr:hover .posi{
		display: block;
	}
	.category .nr .posi{
		width: 126px;
		background: #FFFFFF;
		border: 1px solid rgba(229,230,235,1);
		box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
		border-radius: 6px;
		padding: 13px;
		box-sizing: border-box;
		display: none;
		position: absolute;
		left: 50%;
		margin-left: -63px;
		top: 100%;
	}
	.category .nr .posi .child{
		width: 100px;
		min-height: 32px;
		background: #FFFFFF;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 400;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-align: center;
	}
	.category .nr .posi .child:hover{
		background: #F2F3F5;
	}
	/* .category .nr .posi::after{
		content: '';
		width: 0;
		height: 0;
		border: solid 10px transparent;
		border-bottom: solid 10px #fff;
		position: absolute;
		left: 50%;
		top: -20px;
		margin-left: -5px;
	} */
	.swiper{
		overflow: inherit;
	}
</style>
